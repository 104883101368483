import React, { useEffect } from "react";

const CGW = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://widgets.coingecko.com/coingecko-coin-price-marquee-widget.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  });

  return (
    <coingecko-coin-price-marquee-widget
      coin-ids="bitcoin,ethereum,aave,chainlink,compound-governance-token"
      currency="usd"
      background-color="#191919"
      locale="en"
      font-color="#ffc9ac"
    />
  );
};

export default CGW;
