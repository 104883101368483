import "./App.css";
import {
  Tooltip,
  Box,
  Button,
  HStack,
  ChakraProvider,
  Divider,
  Heading,
  Image,
  VStack,
  Card,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Center,
  Text,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import { FaDiscord, FaGithub, FaRegCopyright } from "react-icons/fa";
import { FaXTwitter, FaRegNewspaper } from "react-icons/fa6";
import { theme } from "./theme";
import { comparisonData, partners, sections } from "./info";
import CGW from "./CoingeckoMarquee";
function App() {
  function FooterNav(o: string) {
    switch (o) {
      case "DISCORD":
        window.open("https://discord.gg/W2bCG5ez", "__blank");
        break;
      case "X":
        window.open("https://twitter.com/liquifidigital", "__blank");
        break;
      case "W":
        t({
          title: "Alert",
          description: "Whitepaper has not been released",
          status: "loading",
          duration: 8000, // Duration in milliseconds
          isClosable: true,
          colorScheme: "orange",
        });

        //window.open("", "__blank");
        break;
      case "G":
        t({
          title: "Alert",
          description: "Source code has not been made public",
          status: "loading",
          duration: 8000, // Duration in milliseconds
          isClosable: true,
          colorScheme: "orange",
        });
        // window.open("", "__blank");
        break;
      default:
        alert("NULL");
    }
  }
  const t = useToast();
  return (
    <ChakraProvider theme={theme}>
      <Box>
        <Box>
          <HStack maxHeight={"min-content"}>
            <Image
              // position={"absolute"}
              top={0}
              left={0}
              boxSize={"10vh"}
              src={"/LD_LOGO.png"}
              className="App-logo"
              alt="logo"
              onClick={() => window.location.reload()}
            />
            <Button
              variant={"action"}
              position={"absolute"}
              top={5}
              right={10}
              width={"10vw"}
              _hover={{
                backgroundColor: "azure",
                color: "#191919",
                border: "3px solid black",
              }}
              onClick={() =>
                t({
                  title: "Alert",
                  description: "Application Currently Under Development",
                  status: "loading",
                  duration: 8000, // Duration in milliseconds
                  isClosable: true,
                  colorScheme: "orange",
                })
              }
            >
              App
            </Button>
          </HStack>
          <CGW />

          <Box>
            <Box
              minHeight="80vh"
              sx={{
                background:
                  "url(/bg.png) no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;",
              }}
            >
              <Heading
                backgroundColor="transparent"
                variant={"title"}
                fontSize={"69pt"}
                padding={"30px"}
              >
                LiquiFi Digital
              </Heading>
              <Heading
                variant={"title"}
                backgroundColor="transparent"
                padding={"3vh"}
                fontSize={"42px"}
              >
                Original Home Equity Farms
              </Heading>
            </Box>

            <Box padding={"50px"}>
              <Divider />
            </Box>
            {sections.map((s, index) => {
              return (
                <Card key={index} justify={"center"} margin={"3vw"}>
                  {index % 2 === 0 ? (
                    <HStack justify={"space-evenly"}>
                      <VStack justify={"center"} textAlign={"left"}>
                        <Heading>{s.title}</Heading>
                        <Box maxWidth={"50vw"} overflowWrap={"break-word"}>
                          {s.desc}
                        </Box>
                      </VStack>
                      <Image boxSize={"20vw"} objectFit={"cover"} src={s.img} />
                    </HStack>
                  ) : (
                    <HStack justify={"space-evenly"}>
                      <Image boxSize={"20vw"} objectFit={"cover"} src={s.img} />
                      <VStack justify={"center"} textAlign={"right"}>
                        <Heading>{s.title}</Heading>
                        <Box maxWidth={"50vw"} overflowWrap={"break-word"}>
                          {s.desc}
                        </Box>
                      </VStack>
                    </HStack>
                  )}
                  <Box padding={"50px"}>
                    <Divider />
                  </Box>
                </Card>
              );
            })}
          </Box>
          <Box margin={"1vh"}>
            <Heading>Landscape</Heading>
            <TableContainer width={"min-content"} margin={"auto"}>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Feature</Th>
                    <Th>LD</Th>
                    <Th>TradFi</Th>
                    <Th>Notes</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {comparisonData.map((data, index) => {
                    return (
                      <Tr key={index}>
                        <Td>{data.category}</Td>
                        <Td>{data.rate}</Td>
                        <Td>{data.otherRate}</Td>
                        <Td maxWidth={"50vw"} style={{ overflow: "hidden" }}>
                          <Box
                            maxH="100px"
                            overflow="auto"
                            sx={{ "&::-webkit-scrollbar": { display: "none" } }}
                          >
                            {data.notes}
                          </Box>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
          <Box>
            <Box padding={"50px"}>
              <Divider />
            </Box>
            <Heading>Powered By</Heading>
            <SimpleGrid
              alignContent={"center"}
              columns={2}
              spacingX="4vw"
              spacingY="2vh"
              display={"flex"}
              justifyContent={"center"}
              // flexWrap={"center"}
            >
              {partners.map((p, index) => {
                return (
                  <SimpleTooltip text={p.name}>
                    <Card
                      maxWidth={"20vw"}
                      margin={"5px"}
                      key={index}
                      variant={"outline"}
                    >
                      <Image alt={p.name} src={p.logo} />
                    </Card>
                  </SimpleTooltip>
                );
              })}
            </SimpleGrid>
          </Box>
          <Box padding={"50px"}>
            <Divider />
          </Box>
          <Box>
            <HStack justify={"space-evenly"} padding={"2vh"}>
              <Image boxSize={"10vh"} src="/LD_LOGO.png" />
              <Center height="30px">
                <Divider orientation="vertical" variant="thick" />
              </Center>
              <SimpleTooltip text="Join Discord Community">
                <FaDiscord onClick={() => FooterNav("DISCORD")} />
              </SimpleTooltip>
              <Center height="30px">
                <Divider orientation="vertical" variant="thick" />
              </Center>
              <SimpleTooltip text="Follow us on X">
                <FaXTwitter onClick={() => FooterNav("X")} />
              </SimpleTooltip>
              <Center height="30px">
                <Divider orientation="vertical" variant="thick" />
              </Center>
              <SimpleTooltip text="Source Code">
                <FaGithub onClick={() => FooterNav("G")} />
              </SimpleTooltip>
              <Center height="30px">
                <Divider orientation="vertical" variant="thick" />
              </Center>
              <SimpleTooltip text="Whitepaper (in progress)">
                <FaRegNewspaper onClick={() => FooterNav("W")} />
              </SimpleTooltip>
            </HStack>

            <VStack justify={"center"}>
              <Text color={"#636363"}>All Rights Reserved</Text>
              <Text color={"#636363"}>1121 Capital, LLC</Text>
              <FaRegCopyright color={"#636363"} />
            </VStack>
          </Box>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default App;

const SimpleTooltip = ({ text, children }: any) => {
  return (
    <Tooltip label={text}>
      <Box as="span">{children}</Box>
    </Tooltip>
  );
};
