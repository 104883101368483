import aave from "./partner_logos/aave.png";
import hum from "./partner_logos/hummingbot.png";
import link from "./partner_logos/chainlink.png";
import avax from "./partner_logos/avalanche.png";
import plaid from "./partner_logos/plaid.png";

export const sections = [
  {
    title: "Tokenized Real Estate Equity",
    desc: "Tokenize your Real Estate Equity and borrow stable assets up to 70% of the equity value",
    img: "/Extraction.gif",
  },
  {
    title: "Home Equity Farming",
    desc: "Leverage AI powered Yield Vaults to put your home equity to work. Real-time risk & profitability analysis allows for automatic borrow/repayment of assets.",
    img: "/Yield Engine.svg",
  },
  {
    title: "Secure Supply Positions",
    desc: "Secure your supplied crypto assets on Aave with equity in real estate",
    img: "/L.gif",
  },
  {
    title: "Property Valuation Oracle",
    desc: "Custom built property valuation oracle on-chain powered by Chainlink",
    img: link,
  },
];
export const comparisonData = [
  {
    category: "Home Equity Lending",
    rate: "☑️",
    otherRate: "☑️",
    notes:
      "Fully Digital, Seemless, Application Process. Get approved almost instantly.",
  },
  {
    category: "Natively On-Chain",
    rate: "☑️",
    otherRate: "❌",
    notes:
      "All assets securely stored on chain and user owned. Interact with transparent markets and real yield.",
  },
  {
    category: "AI Powered Yield Vaults",
    rate: "☑️",
    otherRate: "❌",
    notes:
      "First ever lending product to enable home equity to be direcly allocated to AI Powered Yield Vaults.",
  },
  {
    category: "Tokenized Real Estate Equity",
    rate: "☑️",
    otherRate: "❌",
    notes: "Premier Real Estate Equity Tokenization Platform.",
  },
  {
    category: "Minimized Debt Accrual",
    rate: "☑️",
    otherRate: "❌",
    notes:
      "Real-time profitability analytics to eliminate debt accrual if yield is not sufficient.",
  },
];
export const partners = [
  {
    name: "Plaid",
    logo: plaid,
  },
  {
    name: "hummingbot",
    logo: hum,
  },
  {
    name: "Chainlink",
    logo: link,
  },
  {
    name: "Aave",
    logo: aave,
  },
];
