import { extendTheme } from "@chakra-ui/react";
import { cardTheme, dividerTheme } from "./customThemes";
export const peach = "rgb(255, 201, 172)";
export const theme = extendTheme({
  initialColorMode: "dark",
  useSystemColorMode: false,
  styles: {
    global: (props: any) => ({
      body: {
        bg: "#191919",
        overflowY: "auto",
        color: "#a6a6a6",
      },
    }),
  },
  components: {
    Button: {
      sizes: {
        xl: {
          h: "56px",
          fontSize: "lg",
          px: "32px",
        },
      },
      variants: {
        classic: {
          bg: "#191919",
          boxShadow: `0 0 2px 2px ${peach}`,
          padding: "10px",
          fontFamily: "optima",
          color: "#a6a6a6",
        },
        action: {
          bg: "#191919",
          padding: "10px",
          fontFamily: "optima",
          boxShadow: `0 0 2px 2px ${peach}`,
          color: "#a6a6a6",
        },
      },
      defaultProps: {
        size: "lg", // default is md
        variant: "action", // default is solid
        color: "#a6a6a6",
      },
    },
    Heading: {
      variants: {
        title: {
          bg: "#191919",
          fontFamily: "optima",
          textShadow: `10px 10px black`,
          color: peach,
          fontSize: "8vh",
          padding: "10px",
        },
        subheader: {
          bg: "#191919",
          color: `${peach}`,
          fontFamily: "optima",
          fontSize: "4vh",
          padding: "10px",
        },
        vSmall: {
          fontSize: "2vh",
          fontFamily: "optima",
        },
      },
      // 6. We can overwrite defaultProps
      defaultProps: {
        size: "lg", // default is md
        variant: "subheader", // default is solid
        color: "#a6a6a6",
      },
    },
    Text: {
      defaultProps: {
        fontFamily: "optima",
        color: "#a6a6a6",
      },
    },
    Box: {
      defaultProps: {
        fontFamily: "optima",
        color: "#a6a6a6",
        backgroundColor: "#191919",
      },
    },
    Card: cardTheme,
    Divider: dividerTheme,
  },
});
