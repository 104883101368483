import { cardAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    backgroundColor: "#191919",
    color: "#a6a6a6",
    fontSize: "2vw",
  },
  header: {
    paddingBottom: "2px",
    fontFamily: "Pixel",
    color: "rgb(255, 201, 172)",
  },
  body: {
    paddingTop: "2px",
    fontFamily: "monospace",
  },
  footer: {
    paddingTop: "2px",
    margin: "auto",
  },
});

const sizes = {
  md: definePartsStyle({
    container: {
      borderRadius: "3px",
    },
  }),
};

export const cardTheme = defineMultiStyleConfig({ baseStyle, sizes });

const thick = defineStyle({
  borderWidth: "5px", // change the width of the border
  borderStyle: `solid `, // change the style of the border
  borderRadius: 10, // set border radius to 10
});

export const dividerTheme = defineStyleConfig({
  variants: { thick },
});
